import { firebaseAuthService } from "@munivestor/ui/services";
import { onAuthStateChanged } from "firebase/auth";
import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const location = useLocation();
  const fnCleanup = useRef<() => void>(() => {});

  useEffect(() => {
    firebaseAuthService
      .getFirebaseAuth()
      .then((auth) => {
        fnCleanup.current = onAuthStateChanged(auth, (user) => {
          setIsSignedIn(!!user);
        });
      })
      .catch((err) => {
        console.error(err);
        setIsSignedIn(false);
      });

    return () => fnCleanup.current();
  }, [location.pathname, fnCleanup.current]);

  return isSignedIn;
}
