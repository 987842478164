import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { RouteMap } from "@constants/RouteMap.js";

const publicPaths = Object.keys(RouteMap.Public).map((route) => {
  return route.split(":")[0];
});

export function useIsPublicRoute() {
  const location = useLocation();

  const isPublic = useMemo(() => {
    const currentPath = location.pathname.replace(/^\//, "");
    if (!currentPath) {
      return true;
    }
    return publicPaths.some((pubPath) => currentPath.startsWith(pubPath));
  }, [location.pathname]);

  return isPublic;
}
