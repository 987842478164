import { LoaderFullscreen } from "@munivestor/ui/components";
import { useSignOut } from "@munivestor/ui/hooks";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { RouteMap } from "@constants/RouteMap.js";
import { useUserMetadata } from "@hooks/state/useUserMetadata.js";

export function ProtectedRoute({ children }: { children: ReactNode }) {
  const { data, isLoading, isError } = useUserMetadata({
    retry: false,
  });
  const { mutate: signOut } = useSignOut({
    successUrl: RouteMap.Public.home,
  });

  useEffect(() => {
    if (isError && !isLoading && !data?.email) {
      console.log("You shouldn't be here. Signing out...");
      signOut({ withReturnUrl: true });
    }
  }, [isError, data, isLoading]);

  if (isLoading) {
    return <LoaderFullscreen />;
  }

  return data ? <>{children}</> : null;
}
