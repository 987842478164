import { notifications as mantineToast } from "@mantine/notifications";
import type { NotificationEventType } from "@munivestor/contracts";
import { QueueProcessor } from "@munivestor/utils";
import { useQueryClient } from "@tanstack/react-query";
import { atom, useAtom } from "jotai";
import { createElement, useCallback, useMemo } from "react";
import { MdNotificationsNone } from "react-icons/md";
import { QueryKeys } from "@constants/QueryKeys.js";

const receivedNotifAtom = atom<Map<string, NotificationEventType>>(new Map());

export function useReceivedNotifications() {
  const [receivedNotif, setReceivedNotif] = useAtom(receivedNotifAtom);
  const queryClient = useQueryClient();

  const queueProcessor = useMemo(
    () =>
      new QueueProcessor<NotificationEventType>(async (notif) => {
        console.log("Processing notification", notif.id);
        if (!notif.silent) {
          console.log("Showing notification", notif.id);
          mantineToast.show({
            autoClose: 5000,
            color: "tertiary",
            icon: createElement(MdNotificationsNone),
            title: notif.title,
            message: notif.message || "New notification received",
            withCloseButton: true,
          });
        }
        console.log("Adding notification", notif.id);
        setReceivedNotif((prev) => {
          const newMap = new Map(prev);
          newMap.set(notif.id, notif);
          return newMap;
        });
        // add a delay
        await new Promise((resolve) => setTimeout(resolve, 500));
        queryClient.invalidateQueries({
          queryKey: [QueryKeys.NotificationHistory],
        });
      }),
    [],
  );

  const addNotification = useCallback((notif: NotificationEventType) => {
    queueProcessor.enqueue(notif, notif.id);
  }, []);

  const removeNotification = useCallback((id: string) => {
    setReceivedNotif((prev) => {
      const newMap = new Map(prev);
      newMap.delete(id);
      return newMap;
    });
  }, []);

  const update = useCallback((notif: NotificationEventType) => {
    setReceivedNotif((prev) => {
      if (!prev.has(notif.id)) {
        return prev;
      }
      const newMap = new Map(prev);
      newMap.set(notif.id, notif);
      return newMap;
    });
  }, []);

  const clear = useCallback(() => {
    setReceivedNotif(new Map());
  }, []);

  return {
    receivedNotif,
    addNotification,
    removeNotification,
    update,
    clear,
  };
}
