export class QueueProcessor<TItem> {
  private items: Array<TItem>;
  private keys: Set<number | string>;
  private processing: boolean;
  private callback: (item: TItem) => Promise<void>;

  constructor(callback: (item: TItem) => Promise<void>) {
    this.items = [];
    this.keys = new Set();
    this.processing = false;
    this.callback = callback;
  }

  public enqueue(item: TItem, key?: number | string): void {
    if (key) {
      if (this.keys.has(key)) {
        return;
      }
      this.keys.add(key);
    }
    this.items.push(item);
    this.processQueue();
  }

  private async processQueue(): Promise<void> {
    if (this.processing || !this.items.length) {
      return;
    }

    this.processing = true;

    const item = this.items.shift();
    if (item) {
      await this.callback(item);
    }

    this.processing = false;
    this.processQueue();
  }
}
