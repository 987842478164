import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone.js";
import utc from "dayjs/plugin/utc.js";

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(timezone);

export const dayjsUTC = dayjs.utc;

export function dayjsET(date: Date): dayjs.Dayjs {
  return dayjs(date).tz("America/New_York");
}

export function dayjsETNow(): dayjs.Dayjs {
  return dayjs().tz("America/New_York");
}

export function dayjsETParse(date: string): dayjs.Dayjs {
  return dayjs.tz(date, "America/New_York");
}

export function dayjsETConvert(date: string): dayjs.Dayjs {
  return dayjs(date).tz("America/New_York");
}

export function dayjsETConvertDate(date: Date): dayjs.Dayjs {
  return dayjs(date).tz("America/New_York");
}
