import { AllowNotificationDialog } from "@munivestor/ui/components";
import { useIsSignedIn } from "@hooks/ui/useIsSignedIn.js";
import { useRegisterFCM } from "./hooks/useRegisterFCM.js";
import { useRegisterSSE } from "./hooks/useRegisterSSE.js";

export function ServerNotificationProvider() {
  const isSignedIn = useIsSignedIn();

  const { isOpen, onAllow, onCancel, onReject } = useRegisterFCM({
    isSignedIn,
  });
  useRegisterSSE({ isSignedIn });

  if (!isSignedIn) {
    return null;
  }

  return (
    <AllowNotificationDialog
      title="Allow notifications"
      isOpen={isOpen}
      onAllow={onAllow}
      onClose={onCancel}
      onReject={onReject}
    >
      We use notifications to keep you updated on important events. Would you
      like to enable notifications?
    </AllowNotificationDialog>
  );
}
