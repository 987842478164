import { include } from "named-urls";

export const RouteMap = {
  Public: include("/", {
    home: "",
    signin: "signin",
    signup: "signup/:token?",
    signinHelp: "signin-help",
  }),
  AuthRedirect: "/auth-redirect",

  Onboarding: include("/onboarding/", {
    all: "*",
    municipality: "municipality",
    individualInvestor: "individual-investor",
    institutionalInvestor: "institutional-investor",
    marketProfessional: "market-professional",
  }),

  Municipality: include("/municipality/", {
    all: "*",
    index: "",
    dashboard: "dashboard",
    list: "list",
    auctionList: "auctions",
    details: ":muniId",
    createAuction: "auctions/create",
    editAuction: "auctions/:auctionId/edit",
  }),

  Investor: include("/investor/", {
    all: "*",
    index: "",
    dashboard: "dashboard",
  }),

  MarketProfessional: include("/market-professional/", {
    all: "*",
    index: "",
    dashboard: "dashboard",
  }),

  Auction: include("/auction/", {
    all: "*",
    index: "",
    list: "list",
    view: ":auctionId",
    bid: ":auctionId/bid",
  }),

  MyBids: include("/bids/", {
    all: "*",
    index: "",
    list: "list",
  }),

  Notifications: "/notifications",
  AccessDenied: "/access-denied",
  UnderConstruction: include("/under-construction", {
    index: "",
  }),
};
