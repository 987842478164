import { Box, Button, Flex, Modal } from "@mantine/core";
import { BodyText } from "../../atoms/Typography/BodyText.jsx";
import classes from "./AllowNotificationDialog.module.css";

export interface AllowNotificationDialogProps {
  isOpen: boolean;
  title?: string;
  children: React.ReactNode;
  onAllow: () => void;
  onReject: () => void;
  onClose: () => void;
}

export function AllowNotificationDialog(props: AllowNotificationDialogProps) {
  const {
    isOpen,
    title = "Allow notifications",
    children,
    onAllow,
    onClose,
    onReject,
  } = props;

  return (
    <>
      <Modal
        classNames={{
          title: classes.title,
        }}
        closeOnClickOutside={false}
        closeOnEscape={false}
        title={title}
        withCloseButton={false}
        opened={isOpen}
        onClose={onClose}
      >
        <Flex direction="column" gap="lg">
          <Box flex={1}>
            {typeof children === "string" ? (
              <BodyText level={3} fz="sm">
                {children}
              </BodyText>
            ) : (
              children
            )}
          </Box>
          <Flex gap="sm" justify="flex-start" direction="row-reverse">
            <Button onClick={onAllow}>Yes</Button>
            <Button color="dark.3" variant="outline" onClick={onClose}>
              Not now
            </Button>
            <Button color="red.7" variant="outline" onClick={onReject}>
              Never
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
