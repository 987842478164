import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { LoaderFullscreen } from "@munivestor/ui/components";
import { useToast } from "@munivestor/ui/hooks";
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider as JotaiProvider } from "jotai/react";
import { Suspense } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { z } from "zod";
import { AppTheme } from "@constants/Theme.js";
import { AppRoutes } from "./routes/index.jsx";

const toast = useToast();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const parsedQuery = z
        .object({
          meta: z.object({ errorMessage: z.string().optional() }).optional(),
        })
        .parse(query);
      const parsedError = z
        .object({
          body: z.object({ message: z.string().optional() }).optional(),
        })
        .parse(error);
      if (parsedQuery?.meta?.errorMessage) {
        toast.showError({ message: parsedQuery.meta.errorMessage });
      } else if (parsedError.body && parsedError.body.message) {
        toast.showError({ message: parsedError.body.message });
      }
    },
  }),
});

const browserRouter = createBrowserRouter(AppRoutes);

export function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <MantineProvider theme={AppTheme}>
        <JotaiProvider>
          <Suspense fallback={<LoaderFullscreen />}>
            <RouterProvider router={browserRouter} />
          </Suspense>
          <Notifications limit={3} position="top-center" />
        </JotaiProvider>
      </MantineProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
