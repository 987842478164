import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { firebaseAuthService } from "../services/firebase.js";

interface Params {
  successUrl: string;
}

interface Variables {
  withReturnUrl?: boolean;
}

export function useSignOut(
  params: Params,
): ReturnType<typeof useMutation<void, Error, Variables | void>> {
  const { successUrl } = params;
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation<void, Error, Variables | void>({
    mutationFn: async (variables) => {
      await firebaseAuthService.signOut();
      queryClient.cancelQueries();
      queryClient.invalidateQueries();
      queryClient.resetQueries();

      // add a delay to let things settle
      await new Promise((resolve) => setTimeout(resolve, 250));

      const params =
        variables && variables.withReturnUrl
          ? `?returnUrl=${encodeURIComponent(window.location.pathname)}`
          : "";
      navigate(`${successUrl}${params}`);
    },
  });
}
