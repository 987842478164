const keys = <const>[
  "FIREBASE_VAPID_KEY",
  "RECAPTCHA_SITE_KEY",
  "SENTRY_DSN_FE",
  "SEGMENT_API_KEY",
];

export const EnvVars = keys.reduce<Record<(typeof keys)[number], string>>(
  (acc, key) => {
    return {
      ...acc,
      [key]: import.meta.env[key] ?? import.meta.env[`VITE_${key}`],
    };
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as any,
);
