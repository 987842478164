import {
  NotificationEventSchema,
  NotificationTypeEnum,
} from "@munivestor/contracts";
import { firebaseAuthService } from "@munivestor/ui/services";
import { useEffect, useRef } from "react";
import { useIsPublicRoute } from "@hooks/ui/useIsPublicRoute.js";
import { useReceivedNotifications } from "@hooks/useReceivedNotifications.js";

export function useRegisterSSE({ isSignedIn }: { isSignedIn: boolean }) {
  const fnUnsubscribe = useRef<() => void>(() => {});

  const { addNotification } = useReceivedNotifications();
  const isPublicRoute = useIsPublicRoute();

  useEffect(() => {
    if (isPublicRoute || !isSignedIn) {
      return;
    }

    console.info("Subscribing to SSE");
    firebaseAuthService.getIdToken().then((token) => {
      const eventSource = new EventSource(
        `/api/notifications/sse?token=${token}`,
      );

      const fnHandler = (event: MessageEvent) => {
        try {
          if (typeof event.data === "string") {
            const data = NotificationEventSchema.parse(JSON.parse(event.data));
            console.info("Received SSE", data.id);
            addNotification(data);
          } else {
            const data = NotificationEventSchema.parse(event.data);
            console.info("Received SSE", data.id);
            addNotification(data);
          }
        } catch (err) {
          console.error("Failed to parse SSE data", err);
        }
      };

      eventSource.addEventListener(NotificationTypeEnum.SSEType, fnHandler);
      fnUnsubscribe.current = () => {
        console.info("Unsubscribing from sse");
        eventSource.removeEventListener(
          NotificationTypeEnum.SSEType,
          fnHandler,
        );
        eventSource.close();
      };
    });

    return () => fnUnsubscribe.current();
  }, [isPublicRoute, isSignedIn]);
}
