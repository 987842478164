import { createTheme } from "@mantine/core";

export const AppTheme = createTheme({
  fontFamily: "Inter, sans-serif",
  headings: {
    fontFamily: "Raleway, sans-serif",
  },
  primaryColor: "brand",
  primaryShade: 5,
  colors: {
    brand: [
      "#EDF0D2",
      "#D7E5B4",
      "#B9DA97",
      "#8EC775",
      "#6DC25E",
      "#43B548",
      "#28A745",
      "#219454",
      "#156D61",
      "#105258",
    ],
    secondary: [
      "#FFFAEF",
      "#FFEFC9",
      "#FFE5A3",
      "#FFDC80",
      "#FFCC46",
      "#D4A63B",
      "#AA8230",
      "#806125",
      "#5B441C",
      "#372A13",
    ],
    tertiary: [
      "#CEE2ED",
      "#9FC3D8",
      "#73A1C1",
      "#4A7EA8",
      "#225B8E",
      "#003972",
      "#003668",
      "#002B4C",
      "#00233A",
      "#001B27",
    ],
  },
});
