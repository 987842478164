export enum QueryKeys {
  // Auth
  Auth = "Auth",
  FirebaseConfig = "FirebaseConfig",
  UserMetadata = "UserMetadata",

  // Onboarding
  MunicipalityOnboardingInfo = "Onboarding.MunicipalityInfo",
  IndieInvestorOnboardingInfo = "Onboarding.IndieInvestorInfo",
  InstitutionalInvestorOnboardingInfo = "Onboarding.InstitutionalInvestorInfo",
  MarketProfOnboardingInfo = "Onboarding.MarketProfInfo",

  // Auctions
  AuctionMyBids = "AuctionMyBids",
  AuctionDetails = "AuctionDetails",
  AuctionInventory = "AuctionInventory",
  AuctionListOwn = "AuctionListOwn",
  AuctionListAll = "AuctionListAll",
  AuctionProfessionals = "AuctionProfessionals",
  AuctionProfessionalsByAuction = "AuctionProfessionalsByAuction",
  AuctionMySubscriptions = "AuctionMySubscriptions",

  // Municipality
  MuniInfo = "MuniInfo",
  MarketNewsList = "MarketNewsList",
  MuniStats = "MuniStats",

  // Investor
  InvestorMuniList = "InvestorMuniList",
  InvestorMuniDetails = "InvestorMuniDetails",

  // Market Professional
  MarketProfessionalConnections = "MarketProfessionalConnections",

  // Social
  UserFavMunis = "UserFavMunis",
  UserFavAuctions = "UserFavAuctions",
  UserFavorites = "UserFavorites",

  // Misc
  PublicServiceHost = "PublicServiceHost",
  GetReturnUrl = "GetReturnUrl",
  NotificationHistory = "NotificationHistory",
}
