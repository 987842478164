import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";

import { EnvVars } from "@constants/EnvVars.js";
import { App } from "./src/App.jsx";

import "@css/reset.css";
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import "@css/app.css";

declare global {
  interface Window {
    // for segment analytics
    analytics: any;
  }
}

EnvVars.SENTRY_DSN_FE &&
  Sentry.init({
    dsn: EnvVars.SENTRY_DSN_FE,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });

const container = document.getElementById("root");
if (!container) {
  throw new Error("Root element not found");
}
createRoot(container).render(<App />);
