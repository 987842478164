import { lazyLoad } from "@munivestor/ui/utils";
import { Route, Outlet, createRoutesFromElements } from "react-router-dom";
import { ProtectedRoute } from "@atoms/Routing/ProtectedRoute.jsx";
import { RouteMap } from "@constants/RouteMap.js";
import { ServerNotificationProvider } from "@molecules/ServerNotificationProvider/index.jsx";

// Set up lazy loaded route components for code-splitting
const Signin = lazyLoad(() => import("@features/Auth/SigninPage.jsx"));
const SignUp = lazyLoad(() => import("@features/Auth/SignupPage.jsx"));
const TroubleSignin = lazyLoad(
  () => import("@features/Auth/TroubleSigninPage.jsx"),
);
const OnboardingRoutes = lazyLoad(() => import("./OnboardingRoutes.jsx"));
const MunicipalityRoutes = lazyLoad(() => import("./MunicipalityRoutes.jsx"));
const InvestorRoutes = lazyLoad(() => import("./InvestorRoutes.jsx"));
const MarketProfRoutes = lazyLoad(() => import("./MarketProfRoutes.jsx"));
const AuctionRoutes = lazyLoad(() => import("./AuctionRoutes.jsx"));
const MyBidsRoutes = lazyLoad(() => import("./MyBidsRoutes.jsx"));
const NotificationsHome = lazyLoad(
  () => import("@features/Notifications/index.jsx"),
);
const UnderConstruction = lazyLoad(
  () => import("@features/UnderConstruction/index.jsx"),
);
const AuthRedirect = lazyLoad(
  () => import("@features/Auth/AuthRedirectPage.jsx"),
);
const AccessDenied = lazyLoad(() => import("@features/AccessDenied/index.jsx"));

export const AppRoutes = createRoutesFromElements(
  <>
    {/* https://reactrouter.com/en/main/upgrading/v6-data#but-ive-got-stuff-between-browserrouter-and-routes */}
    <Route
      element={
        <>
          <Outlet />
          <ServerNotificationProvider />
        </>
      }
    >
      {/* Open Routes */}
      <Route path={RouteMap.Public.home} element={<Signin />} />
      <Route path={RouteMap.Public.signin} element={<Signin />} />
      <Route path={RouteMap.Public.signinHelp} element={<TroubleSignin />} />
      <Route path={RouteMap.Public.signup} element={<SignUp />} />

      {/* Protected Routes */}
      <Route path={RouteMap.AuthRedirect} element={<AuthRedirect />} />

      <Route
        path={RouteMap.Onboarding.all}
        element={
          <ProtectedRoute>
            <OnboardingRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.Municipality.all}
        element={
          <ProtectedRoute>
            <MunicipalityRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.Investor.all}
        element={
          <ProtectedRoute>
            <InvestorRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.MarketProfessional.all}
        element={
          <ProtectedRoute>
            <MarketProfRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.Auction.all}
        element={
          <ProtectedRoute>
            <AuctionRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.MyBids.all}
        element={
          <ProtectedRoute>
            <MyBidsRoutes />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.Notifications}
        element={
          <ProtectedRoute>
            <NotificationsHome />
          </ProtectedRoute>
        }
      />
      <Route
        path={RouteMap.UnderConstruction.index}
        element={
          <ProtectedRoute>
            <UnderConstruction />
          </ProtectedRoute>
        }
      />
      <Route path={RouteMap.AccessDenied} element={<AccessDenied />} />
    </Route>
  </>,
);
