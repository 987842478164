import type { PushNotificationPreferencesType } from "@munivestor/contracts";
import {
  NotificationEventSchema,
  PushNotificationPreferencesSchema,
} from "@munivestor/contracts";
import { ApiClient, firebaseMessagingService } from "@munivestor/ui/services";
import { dayjsUTC } from "@munivestor/utils";
import { useCallback, useEffect, useRef, useState } from "react";
import { NOTIF_PREFERENCE } from "@constants/BrowserStorage.js";
import { EnvVars } from "@constants/EnvVars.js";
import { useUserMetadata } from "@hooks/state/useUserMetadata.js";
import { useIsPublicRoute } from "@hooks/ui/useIsPublicRoute.js";
import { useReceivedNotifications } from "@hooks/useReceivedNotifications.js";

export function useRegisterFCM({ isSignedIn }: { isSignedIn: boolean }) {
  const [promptForToken, setPromptForToken] = useState(false);
  const fnUnsubscribePM = useRef<() => void>(() => {});
  const fnUnsubscribeFCM = useRef<() => void>(() => {});

  const { addNotification } = useReceivedNotifications();
  const isPublicRoute = useIsPublicRoute();

  const { data: user } = useUserMetadata({
    enabled: isSignedIn && !isPublicRoute,
  });

  // mutation to register the device token on the server
  const { mutateAsync: save } =
    ApiClient.notifications.registerForNotifications.useMutation();

  // get the push token from firebase and save it to the server
  // also save the notification preferences in local storage
  const getAndSaveToken = useCallback(async () => {
    setPromptForToken(false);
    let token = "";
    let retriesRemaining = 5;
    while (!token && retriesRemaining > 0) {
      try {
        token = await firebaseMessagingService.getToken({
          vapidKey: EnvVars.FIREBASE_VAPID_KEY,
        });
      } catch (err) {
        console.error("Failed to get token", err);
        console.info("Retrying...");
      }
      retriesRemaining--;
    }
    if (!token) {
      console.error("Failed to get token after 5 retries");
      return;
    }
    const tokenPref = loadTokenPref();
    if (!user) {
      return;
    }
    if (
      !tokenPref ||
      (token && token !== tokenPref?.token) ||
      user.uuid !== tokenPref?.userUuid ||
      dayjsUTC(tokenPref?.lastUpdated).isAfter(dayjsUTC().subtract(1, "day"))
    ) {
      // if the token has changed or it's older than 1 day
      await save({ body: { token } });
      // update last updated in local storage
      saveTokenPref({ token, userUuid: user.uuid, allowed: true });
    }
    // start listening to messages
    firebaseMessagingService
      .registerOnMessageCallback(onMessageCallback)
      .then((fnUnsub) => {
        console.info("Subscribed to FCM");
        fnUnsubscribeFCM.current = () => {
          console.log("Unsubscribing from FCM");
          fnUnsub();
        };
      })
      .catch((err) => {
        console.error("Failed to subscribe to FCM", err);
      });
  }, [user]);

  // called when the user allows the notification prompt
  const handleAllow = useCallback(() => {
    if (!user) {
      return;
    }
    saveTokenPref({
      token: "",
      userUuid: user.uuid,
      allowed: true,
    });
    getAndSaveToken();
  }, [getAndSaveToken, user]);

  // called when the user rejects the notification prompt
  const handleReject = useCallback(() => {
    if (!user) {
      return;
    }
    saveTokenPref({
      token: "",
      userUuid: user.uuid,
      allowed: false,
    });
    setPromptForToken(false);
  }, [user]);

  // called when the user cancels/closes the notification prompt
  const handleCancel = useCallback(() => {
    setPromptForToken(false);
  }, []);

  // this callback will be called when a message is received
  const onMessageCallback = useCallback((event: any) => {
    try {
      console.info("Received FCM");
      const data = NotificationEventSchema.parse(
        JSON.parse(event.data.strData),
      );
      console.info("FCM #", data.id);
      addNotification(data);
    } catch (err) {
      console.error("Failed to parse notification event", err);
    }
  }, []);

  useEffect(() => {
    if (isPublicRoute || !isSignedIn) {
      return;
    }

    const tokenPref = loadTokenPref();
    // if the user has REJECTED notifications
    if (tokenPref && !tokenPref.allowed) {
      return;
    }

    if (
      !firebaseMessagingService.hasPermission() || // user has not allowed notifications
      !tokenPref // token preferences have not been saved
    ) {
      // if the current user has not been prompted yet -> show consent dialog
      setPromptForToken(true);
      return;
    }

    getAndSaveToken();
  }, [isPublicRoute, isSignedIn, getAndSaveToken, setPromptForToken]);

  useEffect(() => {
    if (!user || !("serviceWorker" in navigator)) {
      return;
    }

    const fnHandler = (event: MessageEvent) => {
      if (!event.data.strData) {
        return;
      }
      try {
        const data = NotificationEventSchema.parse(
          JSON.parse(event.data.strData),
        );
        console.info("Received FCM from SW", data.id);
        addNotification(data);
      } catch (err) {
        console.error("Failed to parse notification event", err);
      }
    };

    // these messages are sent by the SW when a background message is received
    navigator.serviceWorker.addEventListener("message", fnHandler);
    console.info("Subscribed to messages from SW");
    fnUnsubscribePM.current = () => {
      console.info("Unsubscribing from SW messages");
      navigator.serviceWorker.removeEventListener("message", fnHandler);
    };

    return () => {
      fnUnsubscribeFCM.current();
      fnUnsubscribePM.current();
    };
  }, [user]);

  return {
    isOpen: promptForToken,
    onAllow: handleAllow,
    onCancel: handleCancel,
    onReject: handleReject,
  };
}

function saveTokenPref({
  token,
  userUuid,
  allowed,
}: {
  token: string;
  userUuid: string;
  allowed: boolean;
}) {
  window.localStorage.setItem(
    NOTIF_PREFERENCE,
    JSON.stringify({
      userUuid,
      allowed,
      token,
      lastUpdated: dayjsUTC().toDate(),
    } satisfies PushNotificationPreferencesType),
  );
}

function loadTokenPref() {
  const strNotifPreference = window.localStorage.getItem(NOTIF_PREFERENCE);
  if (strNotifPreference) {
    const validator = PushNotificationPreferencesSchema.safeParse(
      JSON.parse(strNotifPreference),
    );

    if (validator.success) {
      return validator.data;
    }
  }
  return null;
}
